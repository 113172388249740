import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

function CPara(props) {
  return(
    <p className="text-gray-800 py-2">
      {props.children}
    </p>
  )
}

function CUListI(props){
  return(<li className="text-gray-800 pl-2">{props.children}</li>)
}

function COListI(props){
  return(
    <li className="text-gray-800 pl-2">{props.children}</li>
  )
}

function COList(props){
  return(
    <ol className="md:ml-4 pl-4 list-decimal -mt-2 pb-2">{props.children}</ol>
  )
}

function CUList(props) {
  return(
    <ul className="md:ml-4 pl-4 list-disc -mt-2 pb-2">{props.children}</ul>
  )
}

function CHeadingTwo(props) {
  return(
    <h2 className="my-4 text-2xl font-semibold leading-tight">{props.children}</h2>
  )
}

function CHeadingThree(props) {
  return(
    <h3 className="my-4 text-xl font-semibold leading-tight">{props.children}</h3>
  )
}

function CHeadingFour(props) {
  return(
    <h4 className="my-3 font-semibold leading-tight">{props.children}</h4>
  )
}

/*

Rephrase the benefits more clearly:

1. Mark of quality
2. Certify without certification

*/

function MemberPitch(props) {
  return(
    <div className="w-full mx-2 my-4 px-8">
      <CPara>
        Membership of the WCCA is your opportunity to demonstrate to peers, colleagues, employees, or employers
        that you commit to a professional standard for content creation.
      </CPara>
      <CPara>
        Membership of the WCCA is a mark of the quality of a content creator.
      </CPara>
      <CPara>
        <strong>For individuals,</strong> Membership of the WCCA is the opportunity to raise your profile and demonstrate the quality and professionalism of your work.
      </CPara>
      <CPara>
        <strong>For companies and employers</strong>, the WCCA provides confidence that you are hiring individuals with proven experience delivering quality work.
      </CPara>
      <CPara>
        Our members are typically experienced with content creation and seek to demonstrate the quality of their work
        using their status as a Member of the WCCA as endoresement.
      </CPara>
      <CPara>
        Membership of the WCCA can offer a method for individuals to certify themselves without necessarily gaining a qualification in content creation.
      </CPara>
    </div>
  )
}

function MembershipRequirements(props){
  return (
    <div className="w-full mx-2 my-4 px-8">
      <CPara>
        The only requirement to considered for Membership of the WCCA is a demonstration
        that you align with our <Link className="border-b border-blue-200" to="/code-of-conduct">Code of Conduct</Link>.
      </CPara>
      <CPara>
        Alignment with our Code of Conduct is typically evidenced by experience creating content.
        A qualifiation, such as a degree or certificate, can be useful in lieu of some experience,
        though it is not required to become a Member of the WCCA.
      </CPara>
      <CPara>
        There are no minimum amount of experience required however it may be difficult for an applicant
        to demonstrate alignment with our Code of Conduct if they have had less than 2 years experience
        (or 1 year if they hold a qualification) in content creation.
      </CPara>
      <CPara>
        Decisions on membership applications are made by our Membership Committee, in accordance
        with the procedure below. No payment is required to submit an application, an annual membership fee
        becomes applicable only once the Membership Committee has approved the application.
      </CPara>
      <CHeadingThree>Demonstrating how you meet our Code of Conduct</CHeadingThree>
      <CPara>
        Members of the WCCA are expected to uphold the Code of Conduct. To become a member of the WCCA you will
        be required to demonstrate how you meet each of the principles of our Code of Conduct.
      </CPara>
      
    </div>
  )
}

function HowToMembership(props){
  return (
    <div className="w-full mx-2 my-4 px-8">
      <CPara>
        To apply for membership, follow these steps:
      </CPara>
      <COList>
        <COListI>Download and complete the <em>Membership Application Form</em></COListI>
        <COListI>Follow instructions on form for submission</COListI>
        <COListI>If membership is approved, an invoice will be sent to the contact details you provided</COListI>
        <COListI>Upon payment of invoice you will be a member of the WCCA</COListI>
      </COList>
      <CPara>
        Payment is not required until <strong>after</strong> your application is approved.
        Anyone joining the WCCA or renewing their membership in <CurrentYear/> will be requested to
        pay a fee of $168.54&nbsp;(United States Dollars) for one year of membership.
      </CPara>
      <CHeadingThree>Membership Approval</CHeadingThree>
      <CPara>
        All requests to become a member are subject to the approval of the Membership Committee.
      </CPara>
      <CPara>
        Upon receipt of a new application, one committee member will review the application to determine
        whether or not the candidate meets the requirements of membership. In cases where it is not clear that
        the applicant meets or does not meet the requirements of membership, the committee member may seek a second opinion.
        The applicant is then notified of the decision.
      </CPara>
      <CPara>
        An unsuccessful applicant may appeal the decision of the committee. A committee member who was not previously
        involved in the decision regarding the applicaiton in question will then review the appeal with the
        authority to overturn the decision if the reviewing committee member deems appropriate.
      </CPara>
      <CPara>
        While it typically takes up to seven days for the committee to inform the applicant of the outcome
        of their application, there is no fixed timeframe and in rare occasions it may take up to twenty-one days.
      </CPara>
      <CPara>
        If the applicant has any queries about their application, contact details are included on the membership form
        or on our website.
      </CPara>
    </div>
  )
}

function CurrentYear(props){
  return new Date().getFullYear();
}

class Membership extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Membership"
        />
        <div className="container mx-auto max-w-5xl">
          <h1 className="my-4 text-2xl md:text-3xl font-black leading-tight">
            Membership
          </h1>
          <p className="leading-normal text-gray-800 text-base md:text-xl mb-8">
            Join the WCCA to demonstrate and advertise your commitment to content creation
          </p>
        </div>
        <section className="bg-gray-100 border-b py-8">
          <div className="container mx-auto max-w-5xl flex flex-wrap pt-4 pb-12">
            <div className="w-full p-4 flex flex-col flex-grow flex-shrink">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full font-bold text-2xl text-gray-800 px-6 mt-2">
                    Become a Member Today:
                  </div>
                  <MemberPitch/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-8">
          <div className="container mx-auto max-w-5xl flex flex-wrap pt-4 pb-12">

            <div className="w-full p-4 flex flex-col flex-grow flex-shrink">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full font-bold text-2xl text-gray-800 px-6 mt-2">
                    Membership Requirements
                  </div>
                  <MembershipRequirements/>
                </div>
              </div>
            </div>
            
            <div className="w-full p-4 flex flex-col flex-grow flex-shrink">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full font-bold text-2xl text-gray-800 px-6 mt-2">
                    How to Apply for Membership
                  </div>
                  <HowToMembership/>
                </div>
              </div>
            </div>
          </div>
        </section>


      </Layout>
    )
  }
}

/*
        <section className="border-b py-8">
          <div className="max-w-full md:max-w-5xl mx-auto my-3 md:px-8">
            <div className="relative block flex flex-col md:flex-row justify-center">
              <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg mx-1">
                <div className="bg-white text-black rounded-lg overflow-hidden">
                  <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text black px-8 lg:px-6">
                    <h2 className="text-lg uppercase p-3 pb-0 text-center tracking-wide">
                      Personal Membership
                    </h2>
                    <h3 className="text-sm text-gray-500 text-center pb-6">
                      $199/year
                    </h3>
                    <p>
                      Something something something about pricing goes here
                    </p>
                  </div>
                  <div className="flex pl-20 justify-start sm:justify-start mt-3">
                    <ul>
                      <li class="flex items-center">
                        <div
                        className="rounded-full p-2 fill-current text-green-700"
                        >
                          <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          >
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">No setup</span>
                      </li>
                      <li className="flex items-center">
                        <div
                        className="rounded-full p-2 fill-current text-green-700"
                        >
                          <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          >
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                              <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">Hidden fees</span>
                      </li>
                      <li className="flex items-center">
                        <div
                        className=" rounded-full p-2 fill-current text-green-700"
                        >
                          <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">Original</span>
                      </li>
                    </ul>
                  </div>
                  <div className="block flex items-center pb-8">
                    <a href="#" className="text-sm text-center font-semibold underline w-full rounded-lg px-6 py-3">
                      Download Application Form
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-11/12 max-w-sm sm:w-3/5 lg:w-1/3 sm:my-5 my-8 relative z-0 rounded-lg shadow-lg mx-1">
                <div className="bg-white text-black rounded-lg overflow-hidden">
                  <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text black px-8 lg:px-6">
                    <h2 className="text-lg uppercase p-3 pb-0 text-center tracking-wide">
                      Corporate Membership
                    </h2>
                    <h3 className="text-sm text-gray-500 text-center pb-6">
                      $499/year
                    </h3>
                    <p>
                      Something something something about pricing goes here
                    </p>
                  </div>
                  <div className="flex pl-20 justify-start sm:justify-start mt-3">
                    <ul>
                      <li class="flex items-center">
                        <div
                        className="rounded-full p-2 fill-current text-green-700"
                        >
                          <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          >
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                          <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">No setup</span>
                      </li>
                      <li className="flex items-center">
                        <div
                        className="rounded-full p-2 fill-current text-green-700"
                        >
                          <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          >
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                              <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">Hidden fees</span>
                      </li>
                      <li className="flex items-center">
                        <div
                        className=" rounded-full p-2 fill-current text-green-700"
                        >
                          <svg
                          className="w-6 h-6 align-middle"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          >
                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                            <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-lg ml-3">Original</span>
                      </li>
                    </ul>
                  </div>
                  <div className="block flex items-center pb-8">
                    <a href="#" className="text-sm text-center font-semibold underline w-full rounded-lg px-6 py-3">
                      Download Application Form
                    </a>
                  </div>              
                </div>
              </div>
              
            </div>
            <div className="block flex flex-col md:flex-row justify center">
              <div className="w-11/12 max-w-full pl-4 mx-4">
                <p className="text-center font-bold">No payment required until accepted by the Membership Committee</p>
              </div>
            </div>
          </div>
        </section>
        */
export default Membership

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`